<template>
  <div>
    <div class="pageMain" :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''">
      <div class="banner perBan">
        <img :src="banners.image" alt="">
        <div class="w1440">
          <div class="txt">
            <p class="fnt_52">
              {{ globalDataArray.siteTemplateLanguage.banner_welcome }}

              <template v-if="saitiAccessInfo.nickname!='' && saitiAccessInfo.nickname!=null && saitiAccessInfo.nickname!=undefined">
                {{ saitiAccessInfo.nickname }}
              </template>

              <template v-else-if="saitiAccessInfo.username!='' && saitiAccessInfo.username!=null && saitiAccessInfo.username!=undefined">
                {{ saitiAccessInfo.username }}
              </template>
            </p>
            <span class="fnt_18 mail" v-if="saitiAccessInfo.email!='' && saitiAccessInfo.email!=null && saitiAccessInfo.email!=undefined">
              {{ saitiAccessInfo.email }}
            </span>
            <span class="fnt_18" v-else-if="saitiAccessInfo.phone!='' && saitiAccessInfo.phone!=null && saitiAccessInfo.phone!=undefined">
              {{ saitiAccessInfo.phone }}
            </span>
          </div>
        </div>
      </div>

      <div class="personPage">
        <div class="cf w1440">
          <div class="personLeft">
            <personmenu />
          </div>

          <div class="personRight">
            <div class="tit fnt_32">{{ globalDataArray.siteTemplateLanguage.person_signup_record }}</div>

            <div class="antDesignTable">
              <a-table
                row-key="id"
                :scroll="{x:true}"
                :columns="columns"
                :data-source="list"
                :pagination="paginationOpt"
                class="j-table-force-nowrap"
              >
                <template slot="tit" slot-scope="text, record">
                  <router-link target="_blank" v-if="record.competitionDelflag=='0' && record.competitionStatus=='1'" :to="'/competitionInfo?id='+record.competitionParentId" class="compNameBtn">{{ text }}</router-link>
                  <span v-else class="compNameBtn" @click="delCancel(record)">{{ text }}</span>
                </template>

                <template slot="curstatus" slot-scope="text, record">
                  <template v-if="record.competitionDelflag=='1'">
                    {{ globalDataArray.siteTemplateLanguage.has_delete }}
                  </template>

                  <template v-else-if="record.competitionStatus=='0'">
                    {{ globalDataArray.siteTemplateLanguage.has_cancel }}
                  </template>

                  <template v-else>
                    <template v-if="record.competitionSignup=='03'">
                      <template v-if="currentTime < record.localEndtimeStr">
                        {{ record.competitionSignup_dictText }}
                      </template>

                      <!-- 截止报名 -->
                      <template v-if="currentTime >= record.localEndtimeStr">
                        {{ globalDataArray.siteTemplateLanguage.competition_signup_end }}
                      </template>
                    </template>

                    <template v-else>
                      {{ record.competitionSignup_dictText }}
                    </template>
                  </template>
                </template>

                <template slot="action" slot-scope="text, record">
                  <span @click="seeInfo(record)" class="compEditBtn">{{ globalDataArray.siteTemplateLanguage.person_team_member }}</span>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <personedit :personedit.sync="personedit" :curid.sync="curid" />
  </div>
</template>

<script>
import { stringToDateTime } from '@/utils/util'
import { SignupRecordList } from '@/api/person/person'
import { message } from 'ant-design-vue'
import Cookies from 'js-cookie'
import { getAdList } from '@/api/api'
export default {
  name: 'Myrecord',
  data() {
    return {
      title: this.globalDataArray.siteInfo.title,
      description: this.globalDataArray.siteInfo.description,
      keywords: this.globalDataArray.siteInfo.keywords,
      saitiAccessInfo: '',
      paginationOpt: {
        current: 1,
        total: 0,
        defaultCurrent: 1,
        defaultPageSize: 10,
        pageSize: 10,
        hideOnSinglePage: true,
        onChange: (current, size) => {
          this.defaultPageSize = size
          this.paginationOpt.current = current
          this.SignupRecordList()
        },
        // onChange:在分页数量和当前页发生变化时触发
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.pageSize = pageSize
        }
      },
      currentTime: new Date().getTime(), // 当前地区时区时间时间戳
      columns: [
        {
          title: '序号',
          dataIndex: '',
          key: 'rowIndex',
          width: 80,
          align: 'center',
          customRender: (text, record, index) => {
            return ((this.paginationOpt.current - 1) * this.paginationOpt.pageSize + index + 1)
          }
        },
        {
          title: '赛事名称',
          align: 'center',
          width: 180,
          dataIndex: 'competitionTitle',
          scopedSlots: { customRender: 'tit' }
        },
        {
          title: '赛道名称',
          width: 160,
          align: 'center',
          dataIndex: 'categoryTitle'
        },
        {
          title: '报名截止时间',
          align: 'center',
          dataIndex: 'competitionEndTime'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'competitionSignup_dictText',
          scopedSlots: { customRender: 'curstatus' }
        },
        {
          title: '报名时间',
          align: 'center',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],

      list: [],

      personedit: false,
      curid: '',
      banners: {}

    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getAdList()
      this.tableHeader()
      this.SignupRecordList()
      if (Cookies.get('saiti_access_info') != '' && Cookies.get('saiti_access_info') != undefined && Cookies.get('saiti_access_info') != null) {
        this.saitiAccessInfo = JSON.parse(Cookies.get('saiti_access_info'))
      }
      // this.seeInfo();
    },
    tableHeader() {
      const that = this
      that.columns[0].title = that.globalDataArray.siteTemplateLanguage.person_table_number
      that.columns[1].title = that.globalDataArray.siteTemplateLanguage.person_table_competition_title
      // that.columns[2].title = that.globalDataArray.siteTemplateLanguage.competition_bonus
      that.columns[2].title = that.globalDataArray.siteTemplateLanguage.person_table_competition_category
      that.columns[3].title = that.globalDataArray.siteTemplateLanguage.person_table_signup_endtime
      that.columns[4].title = that.globalDataArray.siteTemplateLanguage.person_table_status
      that.columns[5].title = that.globalDataArray.siteTemplateLanguage.person_table_signup_create_time
      that.columns[6].title = that.globalDataArray.siteTemplateLanguage.person_table_controls
      // console.log(this.columns,'columns')
    },
    SignupRecordList() {
      const that = this
      const param = 'page=' + that.paginationOpt.current + '&pageSize=' + that.paginationOpt.defaultPageSize
      SignupRecordList(param).then(res => {
        if (res.success) {
          that.list = res.result.records
          that.paginationOpt.total = res.result.total

          that.list.forEach((item, index) => {
            const endUtcTime = item.endUtcTime
            const localEndtimeStr = new Date(endUtcTime).getTime()
            item['localEndtimeStr'] = localEndtimeStr
          })
        }
      })
    },
    getAdList() {
      const that = this
      const adParam = 'alias=person_banner'
      getAdList(adParam).then(res => {
        if (res.success) {
          that.banners = res.result[0]
        }
      })
    },
    seeInfo(record) {
      // console.log(id)
      const that = this
      that.personedit = true
      that.curid = record
    },

    delCancel(record) {
      const that = this
      if (record.competitionDelflag == '1') {
        // 已删除
        message.error(that.globalDataArray.siteTemplateLanguage.has_delete)
      } else if (record.competitionStatus == '0') {
        // 已下架
        message.error(that.globalDataArray.siteTemplateLanguage.has_cancel)
      }
    }
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: 'description', name: 'description', content: this.description },
        { vmid: 'keywords', name: 'keywords', content: this.keywords }
      ]
    }
  }
}
</script>
<style>
@import '@/assets/css/person.css'
</style>
